import axios from "axios";

const href = window.location.href;
let baseUrlAction = null;
if (href.includes("808") || href.includes("localhost")) {
    //console.log("URL bevat '808' of 'localhost'. URL is: " + href);
    baseUrlAction = "http://localhost/stagion/beer/api_beer.php?action=";
} else {
    baseUrlAction = "api/index.php?action=";
}
//console.log("API's baseUrlAction = " + baseUrlAction);

export const getBeers = async () => {
    try {
        const response = await axios.get(baseUrlAction + 'getBeers');
        //console.log("response (met config en header info) FROM bpv.js = ", response);
        return response.data; 
        // hier moet hij complete response.data teruggeven!!, want je wilt ook de succes en de sMessage in de view afhandelen
        // hier kent hij this.$root bijvoorbeeld, dus geen emit.snackBar, want deze file zit niet in de scope
    } catch (err) {
        console.error(err);
    }
};


export const getBeers2 = async (id, gisting) => {
    console.log("gisting =" + gisting, "id =" + id)
    const url = baseUrlAction + 'getBeers2';
    // const payload = JSON.stringify({ itemId, qty });
    //const payload = { gisting: 'hoge met nof', id: 110, }; // volgorde velden maakt niet meer uit door aanpassing in API
    const payload = { gisting: gisting, id: id }; // volgorde velden maakt niet meer uit door aanpassing in API
    const response = await axios.post(url, payload);
    console.log("response from post getBeers request from bier.js", response);
    return response
}

// export const getBeers = async () => {
//     let url = baseUrlAction + 'getBeers2';
//     // let payload = { gisting: 'hoge met nof', id: 110, }; // volgorde velden maakt niet meer uit door aanpassing in API
//     // let response = await axios.post(url, payload);
//     // console.log("response from post getBeersrequest", response);
//     // const payload = JSON.stringify({ itemId, qty });
//     //const payload = { gisting: 'hoge met nof', id: 110, }; // volgorde velden maakt niet meer uit door aanpassing in API
//     const payload = { gisting: gisting, id: id }; // volgorde velden maakt niet meer uit door aanpassing in API
//     const response = await axios.post(url, payload);
//     console.log("response from post getBeers request from bier.js", response);
//     return response
// }

// export {
//     getBeers,
//     //getBeers2
// }

//api/product.js  
// export const addToCart = async (itemId, qty) => {
//     const payload = JSON.stringify({ itemId, qty });
//     const response = await axios.post("/api/cart", {
//         body: payload
//     });
//     const body = await response.json();
//     return body;
// }
